
import { defineComponent, reactive } from 'vue';
import { ElMessageBox } from 'element-plus';
import { getListSubDistributor, deleteSubDistributor, GetListSubDistributor } from '@/api/common/subDistributor';
import { dialog } from '@/util/dialog-render';

export default defineComponent({
    setup() {
    // 搜索条件
        const disFormData = reactive({
            searchKey: 'Account',
            searchValue: '',
            row: 10,
            page: 1
        });
        // 下拉搜索选项
        const disSearchOptions = [{
            label: WordList.TabelResetPwInHtmlAccount,
            value: 'Account'
        }, {
            label: WordList.TabelPropertyManageEmail,
            value: 'Email'
        }];
        // 用户表格column
        const disColumn = [{
            prop: 'Account',
            label: WordList.TabelResetPwInHtmlAccount
        }, {
            prop: 'Company',
            label: WordList.TabelUserInHtmlCompany
        }, {
            prop: 'Email',
            label: WordList.TabelPropertyManageEmail
        }, {
            prop: 'Phone',
            label: WordList.TabelPersonUserInHtmlPhone
        }, {
            prop: 'op',
            label: WordList.CaptureListTanleAction
        }];
        // 用户表格数据
        const disUserData = reactive<GetListSubDistributor>({
            detail: [],
            row: [],
            total: '0'
        });

        // 获取subdis用户列表
        getDisUserData();
        function getDisUserData() {
            getListSubDistributor(disFormData, (res: GetListSubDistributor) => {
                disUserData.detail = res.detail;
                disUserData.row = res.row;
                disUserData.total = res.total;
            });
        }
        /**
         * 新增编辑subdis
         */
        function setSubDis(type: string, row?: GetListSubDistributor['row'][0]) {
            let dialogProps: Record<string, any> = {
                type,
                onSubmitSubDistributorSuccess: () => {
                    search();
                }
            };
            if (type === 'edit' && row) {
                dialogProps = {
                    type,
                    detailData: {
                        ID: row.ID,
                        UUID: row.UUID,
                        Account: row.Account,
                        Email: row.Email,
                        Phone: row.Phone,
                        Company: row.Company,
                        PayBySubDis: row.PayBySubDis,
                        InstallerUUIDs: row.SelectedInstallers
                    },
                    onSubmitSubDistributorSuccess: () => {
                        search();
                    }
                };
            }
            dialog(
                'views/distributor/user/components/list-sub-dis-set-dialog',
                {
                    props: dialogProps
                }
            );
        }

        /**
        * 删除dis用户
        * @param UUID:string sub dis的id
        */
        function delDis(UUID: string) {
            ElMessageBox.confirm(
                WordList.ProperAllTextDeleteTip,
                WordList.TabelDeleteTitle,
                {
                    confirmButtonText: WordList.TabelFootSure,
                    cancelButtonText: WordList.TabelFootCancel,
                    type: 'warning'
                }
            ).then(async () => {
                deleteSubDistributor({
                    UUID
                }, () => {
                    getDisUserData();
                });
            });
        }

        // 搜索时page置为1
        function search() {
            disFormData.page = 1;
            getDisUserData();
        }

        return {
            disFormData,
            disSearchOptions,
            disColumn,
            disUserData,
            getDisUserData,
            delDis,
            search,
            setSubDis
        };
    }
});
