import { GetRequest } from '@/api/base';

export function getFamilyAppList(params: object, callback: Function) {
    GetRequest('v3/web/community/user/getFamilyAppList', params, callback);
}

export interface GetSubList {
    ID: string;
    UUID: string;
    Name: string;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: string;
    PhoneCode: string;
    Phone: string;
    CreateTime: string;
    Active: string;
}
export function getSubList(params: object, callback: Function) {
    GetRequest('v3/web/community/user/getSubList', params, callback);
}

export function getComUserInfo(params: object, callback: Function) {
    GetRequest('v3/web/community/user/getUserInfo', params, callback);
}

export function getUserInfoSingle(params: object, callback: Function) {
    GetRequest('v3/web/single/user/getUserInfo', params, callback);
}
