
import {
    defineComponent, PropType,
    Ref, ref, reactive, computed
} from 'vue';
import { useStore } from 'vuex';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { timeZone, distributor } from '@/data';
import { user } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';
import { deepCopyObject } from '@/methods/basicFun';
import { FormData, ConnectTypeListData } from './all.type';

const rules = {
    Account: [{
        required: true,
        message: WordList.RuleAccountEmpty,
        trigger: 'blur'
    }, {
        validator: user.checkManageAccount,
        trigger: 'blur'
    }],
    BusinessGroup: [
        {
            required: true,
            message: WordList.RuleBusinessEmpty,
            trigger: 'change'
        }
    ],
    Email: [{
        validator: user.checkEmail,
        trigger: 'blur'
    }]
};

const { timeZoneOptions } = timeZone;
const connectType: Array<ConnectTypeListData> = [{
    label: 'Default',
    value: '3'
}, {
    label: 'TCP',
    value: '1'
}, {
    label: 'UDP',
    value: '0'
}, {
    label: 'TLS',
    value: '2'
}];
export default defineComponent({
    emits: ['close', 'success'],
    components: {
        dialogShell
    },
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        const formRef: Ref<any> = ref(null);
        // 区分编辑、添加
        const titles = {
            add: WordList.TabelUserTitleAdd,
            edit: WordList.TabelPersonUserTitleModify
        };
        const urls = {
            add: 'v3/web/common/installer/add',
            edit: 'v3/web/common/installer/edit'
        };

        const formData = reactive({ ...props.initForm });

        // 根据chargemode动态显示收费选项
        const showChargeMode = computed(() => (distributor.ChargeMode.value === 0));

        // 手机号填写限制数字
        const limitPhone = (val: string) => {
            const reg = /[^\d]]*/g;
            formData.Phone = val.replace(reg, '');
        };
        // 重置密码，返回密码信息
        const ResetPw = () => {
            HttpRequest.post('v3/web/common/manage/resetPwd', {
                ID: formData.ID
            }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, '', 'success')(() => false);
            }, false]);
        };
        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                const insParams = deepCopyObject(formData);
                insParams.Community = insParams.Location || '';
                // v6.5.2 计算Business
                insParams.Business = store.state.enableSmartHome
                    ? formData.BusinessGroup.reduce((prev: number, curr: number) => prev + curr) : '';
                HttpRequest.post(urls[props.operaType], insParams, [(data: { msg: string }) => {
                    noticeBase.messageBox('alert', data.msg, '', 'success')(() => false);
                    emit('close');
                    emit('success');
                }, false]);
                return true;
            });
        };

        /**
         * 判断是否勾选
         * 后端返回的是勾选相加的值 2的幂次方 1 2 4 8....相加
         */
        function isBusiness(num: number) {
            // eslint-disable-next-line no-bitwise
            return (num & formData.Business) === num;
        }

        const businessSwitch = store.state.enableSmartHome;

        // 获取business dis创建用户business权限 进行与运算来判断复选框是否展示
        const disBusiness = Number(localStorage.getItem('business'));
        return {
            formRef,
            titles,
            formData,
            rules,
            submit,
            ResetPw,
            limitPhone,
            timeZoneOptions,
            langOptions,
            showChargeMode,
            connectType,
            isBusiness,
            disBusiness,
            businessSwitch
        };
    }
});
