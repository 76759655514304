import router from '@/router';
import { RequestData, ListHeaderItem, ActionType } from '@/components/common/list';
import {
    distributor, timeZone, accountType, account
} from '@/data';
import { FormData } from '@/components/view/distributor/set-installer-dialog';
import { Ref, ref } from 'vue';
import { getComUserInfo, getUserInfoSingle } from '@/api/community/user';

type UserType = 'installer' | 'officeUser'|'villaUser'|'communityUser'|'subDis';

type SearchKey = Array<{
    name: string;
    label: string;
}>;

type UserPageData = {
    [key in UserType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        searchKey: SearchKey;
        defaultAction: Array<ActionType>;
        deleteUrl: string;
    }
};

function getMenus(basicRouter: string) {
    const disConfig = JSON.parse(localStorage.getItem('disConfig')!);
    const menus = [{
        label: WordList.SubDistributor,
        key: 'subDis',
        path: `/${basicRouter}/user?type=subDis`
    }, {
        label: WordList.ProperAllTextInstaller,
        key: 'installer',
        path: `/${basicRouter}/user?type=installer`
    }, {
        label: WordList.VillaUser,
        key: 'villaUser',
        path: `/${basicRouter}/user?type=villaUser`
    }, {
        label: WordList.NavInHtmlMenuCommunityuser,
        key: 'communityUser',
        path: `/${basicRouter}/user?type=communityUser`
    }, {
        label: WordList.ProperAllTextOfficeUser,
        key: 'officeUser',
        path: `/${basicRouter}/user?type=officeUser`
    }];
    if (disConfig.isSubDis || (!disConfig.isSubDis && !disConfig.disCanCreateSubDis)) {
        return menus.slice(1);
    }
    return menus;
}

const changePath = (key: string, path: string) => {
    router.push(path);
};

const headerIns = [{
    name: 'Account',
    label: WordList.TabelResetPwInHtmlAccount
}, {
    name: 'Community',
    label: WordList.TabelUserInHtmlCompany
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}];

const headerEndUser = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}, {
    name: 'Address',
    label: WordList.TabelCommunitiesAddress
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    name: 'ExpireTime',
    label: WordList.TabelExpretimeBoxSpecificTime
}];
const headerVillaUser = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}, {
    name: 'Address',
    label: WordList.TabelCommunitiesAddress
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    name: 'PremiumPlan',
    label: WordList.PremiumPlan,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    name: 'PremiumPlanExpireTime',
    label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.Premium})\u00a0 \u00a0`
}];
const headerCommunityUser = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}, {
    name: 'Address',
    label: WordList.TabelCommunitiesAddress
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    name: 'Active',
    label: WordList.RDeviceListTanleActive,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    name: 'ExpireTime',
    label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.NavInHtmlMenuApp})\u00a0 \u00a0`
}];
const headerOffice = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Office',
    label: WordList.ProperAllOffice
}, {
    name: 'Phone',
    label: WordList.TabelPersonUserInHtmlPhone
}, {
    name: 'Active',
    label: WordList.RDeviceListTanleActive,
    type: 'customize'
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}, {
    name: 'ExpireTime',
    label: `${WordList.TabelExpretimeBoxSpecificTime}(${WordList.NavInHtmlMenuApp})`
}];

const searchKeyIns = [{
    name: 'Account',
    label: WordList.TabelResetPwInHtmlAccount
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}];
const searchKeyEndUser = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}];
const searchKeyVillaUser = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}];
const searchKeyCommunityUser = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}];
const searchKeyOfficeUser = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Office',
    label: WordList.ProperAllOffice
}];
displayControl();
function displayControl() {
    const disConfig = JSON.parse(localStorage.getItem('disConfig')!);
    // 在 dis下修改表格列和筛选项
    if (!disConfig.isSubDis && disConfig.disCanCreateSubDis) {
        headerIns.push({
            name: 'SubDis',
            label: WordList.SubDistributor
        });
        searchKeyIns.push({
            name: 'SubDis',
            label: WordList.SubDistributor
        });

        const villaFamilyMasterIndex = headerVillaUser.findIndex((item) => item.name === 'FamilyMaster');
        headerVillaUser.splice(villaFamilyMasterIndex, 0, {
            name: 'SubDis',
            label: WordList.SubDistributor
        });
        searchKeyVillaUser.push({
            name: 'SubDis',
            label: WordList.SubDistributor
        });

        const communityFamilyMasterIndex = headerCommunityUser.findIndex((item) => item.name === 'FamilyMaster');
        headerCommunityUser.splice(communityFamilyMasterIndex, 0, {
            name: 'SubDis',
            label: WordList.SubDistributor
        });
        searchKeyCommunityUser.push({
            name: 'SubDis',
            label: WordList.SubDistributor
        });

        const officeFamilyMasterIndex = headerOffice.findIndex((item) => item.name === 'Office');
        headerOffice.splice(officeFamilyMasterIndex, 0, {
            name: 'SubDis',
            label: WordList.SubDistributor
        });
        searchKeyOfficeUser.push({
            name: 'SubDis',
            label: WordList.SubDistributor
        });
    }
}

const getUserPageData = () => {
    const installerRequestData: RequestData = {
        url: 'v3/web/common/installer/getList',
        param: {
            searchKey: 'Account',
            searchValue: ''
        }
    };
    const endUserRequestData: RequestData = {
        url: 'v3/web/community/user/getListForManage',
        param: {
            searchKey: 'Sip',
            searchValue: '',
            role: 'all'
        }
    };
    const villaUserRequestData: RequestData = {
        url: 'v3/web/single/user/getListForManage',
        param: {
            searchKey: 'Sip',
            searchValue: '',
            role: 'all'
        }
    };
    const communityUserRequestData: RequestData = {
        url: 'v3/web/community/user/getListForManage',
        param: {
            searchKey: 'Sip',
            searchValue: '',
            role: 'all'
        }
    };
    const officeUserRequestData: RequestData = {
        url: 'v3/web/office/user/getOfficeUserList',
        param: {
            searchKey: 'Sip',
            searchValue: ''
        }
    };
    const userPageData: UserPageData = {
        subDis: {
            header: headerIns,
            requestData: installerRequestData,
            searchKey: searchKeyIns,
            defaultAction: [],
            deleteUrl: ''
        },
        installer: {
            header: headerIns,
            requestData: installerRequestData,
            searchKey: searchKeyIns,
            defaultAction: [],
            deleteUrl: 'v3/web/common/installer/delete'
        },
        villaUser: {
            header: headerVillaUser,
            requestData: villaUserRequestData,
            searchKey: searchKeyVillaUser,
            defaultAction: ['info'],
            deleteUrl: ''
        },
        communityUser: {
            header: headerCommunityUser,
            requestData: communityUserRequestData,
            searchKey: searchKeyCommunityUser,
            defaultAction: ['info'],
            deleteUrl: ''
        },
        officeUser: {
            header: headerOffice,
            requestData: officeUserRequestData,
            searchKey: searchKeyOfficeUser,
            defaultAction: ['info'],
            deleteUrl: ''
        }
    };
    return userPageData;
};

const initFormData: FormData = {
    Role: distributor.InstallerIdentity,
    Account: '',
    Password: '',
    Email: '',
    TimeZone: timeZone.getManageDefaultTimeZone(),
    ChargeMode: '0',
    SipType: '3',
    Confusion: false,
    Language: Lang,
    Location: '',
    Phone: '',
    IsPwdFreeLogin: 0,
    Business: 0,
    BusinessGroup: []
};

const getInfo = (id: string, role: string) => {
    const info: Ref<
    Array< {name: string; label: string; content: string} >
    > = ref([{
        name: 'Name',
        label: WordList.NavInHtmlMenuUser2,
        content: ''
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail,
        content: ''
    }, {
        name: 'Phone',
        label: WordList.TabelPersonUserInHtmlPhone,
        content: ''
    }, {
        name: 'Address',
        label: WordList.TabelCommunitiesAddress,
        content: ''
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime,
        content: ''
    }]);

    // 获取end user详情
    if (role === '20' || role === '21') {
        getComUserInfo({ ID: id }, (res: accountType.DisGetEndUserInfo) => {
            info.value.forEach((item, index) => {
                const key = item.name as keyof accountType.DisGetEndUserInfo;
                if (key === 'Name') {
                    info.value[index].label = role === '21' ? WordList.NavInHtmlMenuUser2 : WordList.PersonuserFamilyMaster;
                }
                info.value[index].content = res[key] || '--';
            });
        });
    } else if (role === '10' || role === '11') {
        account.getPersonalUserInfo(id, (res: accountType.DisGetEndUserInfo) => {
            info.value.forEach((item, index) => {
                const key = item.name as keyof accountType.DisGetEndUserInfo;
                if (key === 'Name') {
                    info.value[index].label = role === '11' ? WordList.NavInHtmlMenuUser2 : WordList.PersonuserFamilyMaster;
                }
                info.value[index].content = res[key] || '--';
            });
        });
    }

    return {
        info
    };
};

const getOfficeInfoDevice = (id: string) => {
    const deviceHeaders: Array<ListHeaderItem> = [{
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }];
    const devRequestData: RequestData = {
        url: 'v3/web/office/device/getOwnerList',
        param: {
            ID: id
        }
    };
    return {
        deviceHeaders,
        devRequestData
    };
};

export default null;
export {
    getMenus,
    UserType,
    changePath,
    getUserPageData,
    SearchKey,
    initFormData,
    getInfo,
    getOfficeInfoDevice,
    displayControl
};
