
import {
    defineComponent, computed,
    PropType, watch, Ref, ref
} from 'vue';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import {
    ListHeaderItem, RequestData,
    ActionType, listPagination, ListActionItem
} from '@/components/common/list';
import router, { distributorBaseRouter, distributorSubRouter } from '@/router';
import setInstallerDialog, { FormData } from '@/components/view/distributor/set-installer-dialog';
import addButton from '@/components/common/add-button/index.vue';
import remove from '@/methods/remove-func';
import { deleteInstaller } from '@/api/common/installer';
import { getPwdFreeLoginToken } from '@/api/common/pwdFreeLogin';
import Notice from '@/util/notice.base';
import JumpPage from '@/components/view/common/jump-page';
import { premiumPlanFieldMap, activeFieldMap } from '@/constant/field-map';
import listSubDis from './components/list-sub-dis.vue';
import {
    getMenus, UserType,
    changePath, getUserPageData,
    SearchKey, initFormData
} from './util';

export default defineComponent({
    components: {
        secondaryNav,
        listPagination,
        setInstallerDialog,
        addButton,
        JumpPage,
        listSubDis
    },
    props: {
        type: {
            type: String as PropType< UserType >,
            default: ''
        }
    },
    setup(props) {
        const disConfig = JSON.parse(localStorage.getItem('disConfig')!);

        const secondMenus = getMenus(distributorBaseRouter);
        const activeNavItem = ref<UserType>('subDis');
        if (disConfig.isSubDis) {
            activeNavItem.value = 'installer';
        } if (!disConfig.isSubDis && disConfig.disCanCreateSubDis) {
            activeNavItem.value = 'subDis';
        } else {
            activeNavItem.value = 'installer';
        }
        watch(() => props.type, (newVal) => {
            if (newVal) {
                activeNavItem.value = newVal;
            }
        }, { immediate: true });

        // list 所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const searchKeyList: Ref<SearchKey> = ref([]);
        const defaultAction: Ref<Array<ActionType>> = ref([]);
        const deleteUrl = ref('');
        const updateToList = ref(0);

        // 6.5.2免密登录需求：ins用户列表action新增免密登录按钮
        const isShowJumpPage = ref(false);
        const jumpUrl = ref('');
        const insAction = ref<ListActionItem[]>([{
            type: 'loginBtn',
            class: 'el-icon-my-redirect-without-pwd redirect-without-pwd-icon',
            event(val: { ID: string }) {
                Notice.messageBox(
                    'confirm',
                    WordList.RedirectAccountConfirm,
                    WordList.TabelDeleteTitle,
                    'warning'
                )(() => {
                    // 获取临时token，跳转登录页获取token
                    getPwdFreeLoginToken({
                        ID: val.ID
                    }, (res: {
                        TmpToken: string;
                    }) => {
                        // 不能正常打开窗口，引导用户点击
                        window.TmpToken = res.TmpToken;
                        const newPage = window.open(`#/${distributorBaseRouter}/${distributorSubRouter.preLoginIns}`);
                        if (newPage === null) {
                            jumpUrl.value = `#/${distributorBaseRouter}/${distributorSubRouter.preLoginIns}`;
                            isShowJumpPage.value = true;
                        }
                    });
                });
            },
            showCondition(val: {IsShowFreeLoginButton: number}) {
                // dis和ins均开启免密登录选项，显示免密登录按钮
                return val.IsShowFreeLoginButton === 1;
            },
            title: WordList.RedirectWithoutPwd
        }, {
            type: 'edit2',
            class: 'el-icon-my-devices modify-icon',
            title: WordList.TabelPersonDeviceInHtmlMAC,
            event(val: { ID: string }) {
                router.push({ path: '/distributorIndex/mac', query: { id: val.ID } });
            }
        }, {
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    deleteInstaller({
                        ID: data.ID
                    }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }]);
        function closeJumpPage() {
            isShowJumpPage.value = false;
            window.TmpToken = '';
        }

        const userPageData = getUserPageData();
        watch(activeNavItem, () => {
            // 监听头部导航栏切换，更新搜索框、list相关数据
            const pageData = userPageData[activeNavItem.value];
            headers.value = pageData.header;
            listRequestData.value = pageData.requestData;
            searchKeyList.value = pageData.searchKey;
            defaultAction.value = pageData.defaultAction;
            deleteUrl.value = pageData.deleteUrl;
            if (activeNavItem.value !== 'installer') {
                insAction.value = [];
            }
        }, {
            immediate: true
        });

        const showInsDiaLog = ref(false);
        const operaType = ref('');
        const initForm = ref<FormData>({ ...initFormData });
        const addInstaller = () => {
            // 新增安装商
            operaType.value = 'add';
            initForm.value = initFormData;
            showInsDiaLog.value = true;
        };
        const editInstaller = (info: FormData) => {
            // 编辑安装商弹窗，重置操作operaType=edit
            operaType.value = 'edit';
            initForm.value = info;
            initForm.value.BusinessGroup = [];
            showInsDiaLog.value = true;
        };

        const goToEndUserInfo = (detail: {
            ID: string;
            Role: string;
        }) => {
            if (activeNavItem.value === 'villaUser' || activeNavItem.value === 'communityUser') {
                router.push(`/${distributorBaseRouter}/${distributorSubRouter.userInfo}?id=${detail.ID}&role=${detail.Role}`);
            } else if (activeNavItem.value === 'officeUser') {
                router.push(`/${distributorBaseRouter}/${distributorSubRouter.officeInfo}?id=${detail.ID}`);
                localStorage.setItem('officeInfo', JSON.stringify(detail));
            }
        };

        return {
            secondMenus,
            activeNavItem,
            changePath,
            updateToList,
            listRequestData,
            searchKeyList,
            headers,
            deleteUrl,
            defaultAction,
            editInstaller,
            addInstaller,
            operaType,
            showInsDiaLog,
            initForm,
            goToEndUserInfo,
            insAction,
            isShowJumpPage,
            closeJumpPage,
            jumpUrl,
            disConfig,
            premiumPlanFieldMap,
            activeFieldMap
        };
    }
});
