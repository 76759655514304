/* eslint-disable import/prefer-default-export */
import { GetRequest, PostRequest, successParam } from '@/api/base';

export interface GetListSubDistributor {
    total: string;
    row: {
        ID: string;
        Account: string;
        Company: string;
        Email: string;
        PayBySubDis: string;
        Phone: string;
        SelectedInstallers: string[];
        UUID: string;
    }[];
    detail: {
        Account: string;
        Company: string;
        Email: string;
        PayBySubDis: string;
        Phone: string;
        SelectedInstallers: string[];
        UUID: string;
    }[];
}
export function getListSubDistributor(params: object, callback: Function) {
    GetRequest('v3/web/common/subDistributor/getList', params, callback);
}
export function deleteSubDistributor(params: object, callback: successParam) {
    PostRequest('v3/web/common/subDistributor/delete', params, callback);
}
